import AdFormat from "./AdFormat";
import React from "react";

const defaultAds: any[] = [];

export function Ads({ads = defaultAds, format, className}: AdsProps) {
    return (
        <div className={className}>
            {ads.map((ad, i) => <AdFormat key={i} {...ad} type={format} />)}
        </div>
    );
}

export interface AdsProps {
    ads?: any[];
    format?: string;
    className?: string;
}

export default Ads;