import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function LastNameBuiltField(props: LastNameBuiltFieldProps) {
    return <TextBuildField name={'lastName'} {...props} />
}

export interface LastNameBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default LastNameBuiltField;