export function cleanValue(type: string, value: any) {
    if (!value) return value;
    if ('string' !== typeof value) return value;

    switch (type) {
        case 'email': return value.replace(/[^a-z0-9_\-+@.]+/gi, '');
        case 'phone': return value.replace(/[^0-9+]+/gi, '');
        case 'firstName': return value;
        case 'lastName': return value;
        default: return value;
    }
}