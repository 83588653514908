import React from 'react';
import FramedBlock from "../../mods/sale/molecules/FramedBlock";

export function TextAdFormat({ url, presentation }: TextAdFormatProps) {
    return (
        <FramedBlock className={'hover:bg-go-primary hover:text-white cursor-pointer'}>
            <a href={url} target="_blank">
                {presentation}
            </a>
        </FramedBlock>
    );
}

export interface TextAdFormatProps {
    url?: string;
    presentation?: string;
}

export default TextAdFormat;