import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function CityBuiltField(props: CityBuiltFieldProps) {
    return <TextBuildField name={'city'} {...props} />
}

export interface CityBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default CityBuiltField;