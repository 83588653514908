import React from 'react'
import useTrans from "@gotombola/react-i18n/lib/hooks/useTrans";
import String from "@gotombola/react-ds/lib/atoms/String";

export function TermsLink({url}: TermsLinkProps) {
    const {t} = useTrans();

    if (!url) return null;

    return (
        <div className={'w-full text-center justify-center mt-12 font-go-text text-go-text font-light text-sm'}>
            <a href={url} target={'_blank'} className={'underline hover:no-underline'}><String>{t('game_buy_terms_link_for_more')}</String></a>
        </div>
    );
}

export interface TermsLinkProps {
    url?: string;
}

export default TermsLink;