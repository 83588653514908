import {useMemo} from "react";
import {buildBonusesFromCustomBonuses} from "../utils/helpers";
import computeCustomBonuses from "../utils/computeCustomBonuses";

const defaultCustomBonuses = '1|0,0|1,0|2*,0|5,';

export function useGameDefaultBonuses(game: any, { t }: { t: Function }) {
    return useMemo(
        () => buildBonusesFromCustomBonuses(
            computeCustomBonuses(
                game.features,
                game.customBonuses,
                defaultCustomBonuses
            ),
            { t },
            game.ticketPriceCurrency
        ),
        [t, game.customBonuses, game.features, game.ticketPriceCurrency]
    );
}

export default useGameDefaultBonuses;
