import {default as email} from './EmailBuiltField';
import {default as phone} from './PhoneBuiltField';
import {default as firstName} from './FirstNameBuiltField';
import {default as lastName} from './LastNameBuiltField';
import {default as city} from './CityBuiltField';
import {default as zipCode} from './ZipCodeBuiltField';
import {default as country} from './CountryBuiltField';
import {default as birthdate} from './BirthdateBuiltField';
import {default as age} from './AgeBuiltField';
import {default as organization} from './OrganizationBuiltField';
import {default as table} from './TableBuiltField';
import {default as group} from './GroupBuiltField';
import {default as id} from './IdBuiltField';
import {default as ticketQuantity} from './TicketQuantityBuiltField';
import {default as donation} from './DonationBuiltField';

const types = {
    email, phone, firstName, lastName, city, zipCode, country, birthdate, age, organization, id,
    table, group, ticketQuantity, donation,
} ;

export default types;