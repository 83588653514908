import React, {useMemo} from "react";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";

export function useGamePromisePayments(promise: any, game: any, { t }: { t: Function }) {
    const query = useQueryString();
    const paymentMeans = ['twint', 'stripe', 'paypal', 'helloasso', 'lydia', 'paypal_account', 'promise'].map((feature: string) => {
        if (!game.features || !game.features.includes(feature)) return null;
        switch (feature) {
            case "stripe":
                return {id: "stripe-card", label: t('promise_payment_page_stripe_option')}
            case "helloasso":
                return {id: "helloasso", label: t('promise_payment_page_helloasso_option')}
            case "lydia":
                return {id: "lydia", label: t('promise_payment_page_lydia_option')}
            case "paypal":
                return {id: "paypal", label: t('promise_payment_page_paypal_option')}
            case "paypal_account":
                return {id: "paypal", label: t('promise_payment_page_paypal_account_option')}
            case "twint":
                return {id: "twint", label: t('promise_payment_page_twint_option')}
            default:
                return null
        }
    }).filter((item: any) => !!item);
    return useMemo(() => (paymentMeans || []).map((paymentMean: any, index: number) => {
        query.set('p', paymentMean.id);
        const alone = paymentMeans.length === 1;
        return {
            id: paymentMean.id || index,
            target: `${process.env.RAZZLE_GOTOMBOLA_API_PAYMENT_ENDPOINT}/payments/complete/game-promises/${promise.id}?${query.toString()}`,
            helper: <>
                {(!alone || (alone && !paymentMean.ifAlone)) && !!paymentMean.helper && (
                    <div className={'font-go-text text-go-text pt-4'}>{paymentMean.helper}</div>
                )}
                {alone && !!paymentMean.ifAlone && (
                    <div className={'font-go-text text-go-text pb-4'}>{paymentMean.ifAlone}</div>
                )}
            </>,
            label: paymentMean.label,
        }
    }), [paymentMeans, query, promise]);
}

export default useGamePromisePayments;
