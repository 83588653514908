import React, {useMemo} from 'react';
import adFormats from './ad-formats';

export function AdFormat({ type, ...props }: AdFormatProps) {
    const Comp = useMemo(() => adFormats[type || 'text'] || adFormats['text'], [type]);
    if (!Comp) return null;
    return <Comp {...props} />;
}

export interface AdFormatProps {
    type?: string;
    [key: string]: any;
}

export default AdFormat;