import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function BirthdateBuiltField(props: BirthdateBuiltFieldProps) {
    return <TextBuildField name={'birthdate'} {...props} type={'date'} label />
}

export interface BirthdateBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default BirthdateBuiltField;