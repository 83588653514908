import {useMemo} from "react";
import {buildBonusesFromCustomBonuses} from "../utils/helpers";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import formatRawAmount from "@gotombola/amount/lib/utils/formatRawAmount";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";

const defaultCustomBonuses = '0|1,0|2*,0|5,-';

export function useGameBuyBookBonuses(book: any, game: any, { t, workflows }: { t: Function; workflows: any }) {
    const urlBuilder = useBuildUrlFor('game_buy_book', book, false);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'bookpayment', 'bonus', {game, book});
    const quantity = Number(query.get('q') || "0");
    const defaultBonuses = useMemo(() => buildBonusesFromCustomBonuses(game.customBonuses || defaultCustomBonuses, {t}, game.ticketPriceCurrency), [t, game.customPacks, game.ticketPriceCurrency]);
    return useMemo(() => (defaultBonuses || []).filter(x => x.tickets <= 0).map((donation: any, index) => {
        query.set('q', quantity + donation.tickets);
        const extraCost = (donation.tickets ? (donation.tickets * game.ticketPriceAmount) : 0) + (donation.donation * 100);
        if (donation.donation > 0) {
            query.set('d', String(donation.donation * 100));
        } else {
            query.delete('d');
        }
        return {
            id: `donation-${index}`,
            target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
            label: donation.label,
            overline: donation.overline,
            value: extraCost > 0 ? `+ ${formatRawAmount(extraCost, game.ticketPriceCurrency)}` : '-',
        };
    }), [query, quantity, game.ticketPriceAmount, game.ticketPriceCurrency, urlBuilder]);
}

export default useGameBuyBookBonuses;
