import formatAmount from "@gotombola/amount/lib/utils/formatAmount";
import {buildFormFromDsn} from "./form";

export function buildBonusesFromCustomBonuses(customPacks: string, {t}: {t: Function}, currency: string, {onlyDonation} = {onlyDonation: false}) {
    return customPacks.split(/\s*,\s*/g).reduce((acc, p) => {
        let popular = false;
        let item = {label: '', tickets: 0, donation: 0};
        let n = p;
        if ('*' === p.slice(-1)) {
            n = p.slice(0, p.length - 1);
            popular = true;
        }
        const arr = n.split('|');
        if (1 === arr.length && !parseInt(arr[0])) {
            item.label = t('game_buy_book_bonus_page_add_donation_none');
        } else {
            item.tickets = parseInt(arr[0]) || 0;
            item.donation = parseInt(arr[1]) || 0;
            item.label = item.tickets ? t(`game_buy_book_bonus_page_add_ticket${item.tickets === 1 ? '_one' : '_other'}`, {ticketsNumber: item.tickets}) : t('game_buy_book_bonus_page_add_donation', {donationAmount: formatAmount(item.donation, currency)});
        }
        (onlyDonation && 0 === parseInt((arr[0])) || !onlyDonation) && acc.push({
            ...item,
            ...(popular ? {overline: t('game_buy_book_bonus_page_overline_popular')} : {}),
        });
        return acc;
    }, [] as {label: string, tickets: number, donation: number, overline?: string}[]);
}

export function buildOwnerForm(def: string|undefined) {
    return buildFormFromDsn(def);
}
