import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function EmailBuiltField(props: EmailBuiltFieldProps) {
    return <TextBuildField name={'email'} {...props} type={'email'} />
}

export interface EmailBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default EmailBuiltField;