import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function GroupBuiltField(props: GroupBuiltFieldProps) {
    return <TextBuildField name={'group'} {...props} />
}

export interface GroupBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default GroupBuiltField;