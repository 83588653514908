import isOwnerValid from "@gotombola/react-ds/lib/utils/isOwnerValid";
import {cleanValue} from "./cleaner";

const fieldTypes = {
    ticketQuantity: {
        validators: [ { type: 'min', min: 1 }, { type: 'max', max: 500 }]
    }
}

function addField(acc: {content: any[], validators: any[]}, def: {type: string; autofocus?: boolean; required?: boolean}) {
    acc.content.push({...def});
}
function addValidator(acc: {content: any[], validators: any[]}, def: {type: string, value?: any}) {
    acc.validators.push({...def});
}

function testRequiredValidator(field: string, values: any) {
    switch(field) {
        case 'email': return isOwnerValid(values?.[field]);
        case 'phone': return isOwnerValid(values?.[field]);
        case 'age': return Number(values?.[field]) > 0;
        default: return !!values?.[field];
    }
}

function testValidator({type, value, ...opts}: {type: string, value: any} & any, a: any) {
    switch (type) {
        case 'required': return testRequiredValidator(value, a);
        case 'or': return !!value.find((vv: string) => testRequiredValidator(vv, a));
        case 'min': return a[value] >= opts.min;
        case 'max': return a[value] <= opts.max;
        default: return true;
    }
}
function buildSubmittable(validators: any[]) {
    return (a: any) => !validators.find(v => !testValidator(v, a));
}

export function buildFormFromDsn(dsn: string|undefined) {
    const x: {content: any[], submittable: Function} = {content: [], submittable: () => true};

    const {content, validators} = (dsn || '').split(/\s*,\s*/g).reduce((acc, item) => {
        if (!item) return acc;
        let required = false;
        let autofocus = false;
        if ('*' === item.slice(-1)) {
            required = true;
            item = item.slice(0, -1);
        }
        if ('!' === item.slice(-1)) {
            required = true;
            item = item.slice(0, -1);
        }
        if ('!' === item.slice(0, 1)) {
            autofocus = true;
            item = item.slice(1);
        }
        if (!item.length) return acc;
        if (-1 < item.indexOf('|')) {
            const parts = item.split(/\|/g);
            parts.forEach((it, ii) => {
                addField(acc, {type: it, autofocus: (0 === ii) && autofocus});
            })
            required && addValidator(acc, {type: 'or', value: parts});
        } else {
            addField(acc, {type: item, required, autofocus});
            required && addValidator(acc, {type: 'required', value: item});
            fieldTypes[item] && fieldTypes[item].validators?.forEach((x: any) => addValidator(acc, {value: item, ...x}));
        }
        return acc;
    }, {content: [], validators: []} as {content: any[], validators: any[]});

    if (!content.find(x => !!x.autofocus) && content.length) {
        content[0].autofocus = true;
    }
    x.content = [...content];
    x.submittable = buildSubmittable(validators);
    return x;
}

export function cleanAllValue(value: any) {
    if (!value || ('object' !== typeof value)) return value;
    return Object.entries(value).reduce((acc, [k, v]) => {
        acc[k] = cleanValue(k, v);
        return acc;
    }, {} as any);
}

