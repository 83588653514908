import {useMemo} from "react";
import {applySort} from "../utils/sort";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";

export function useGameBuySellergroups(game: any, { t, workflows }: { t: Function; workflows: any }) {
    const urlBuilder = useBuildUrlFor('game', game, false);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'seller_group', {game});
    const rawSellergroups: any[] = (game.sellergroups && game.sellergroups.items) || [];
    const gameDomain = game.domain || undefined;
    const sellergroupGrouping = query.get('sgg');
    const sellergroups = useMemo(() => {
        return [...rawSellergroups].reduce((acc: any, sellergroup: any) => {
            if (!!sellergroup.code) {
                query.set('sg', sellergroup.code);
            } else {
                query.delete('sg');
            }
            const label = sellergroup.name.split(/[,.\s]/)[0];
            if (!sellergroupGrouping || (label === sellergroupGrouping)) {
                acc.push({
                    id: sellergroup.code,
                    target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
                    label: (sellergroup.name || '').trim(),
                    rank: sellergroup.rank,
                })
            }
            return acc;
        }, []);
    }, [rawSellergroups, query, game, nextStep, sellergroupGrouping, urlBuilder]);
    return useMemo(() => {
        query.delete('sg');
        applySort(sellergroups, game?.sellergroupSortMode, 'default');
        sellergroups.push({
            id: null,
            target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
            label:  t([
                `game_buy_seller_group_other_choice_common_${game?.sellergroupType}`,
                `game_buy_seller_group_other_choice_${gameDomain}`,
                `game_buy_seller_group_other_choice_generic`,
            ]),
        });
        return sellergroups;
    }, [game, nextStep, query, gameDomain, sellergroups, urlBuilder]);
}

export default useGameBuySellergroups;
