import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function DonationBuiltField(props: DonationBuiltFieldProps) {
    return <TextBuildField name={'donation'} {...props} type={'number'} min={0} max={10000} />
}

export interface DonationBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default DonationBuiltField;