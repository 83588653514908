import React from "react";

export function buildPacksFromCustomPacks(customPacks: string, {t}: {t: Function}) {
    customPacks = (customPacks || '').trim();
    if ('1' === customPacks) {
        return [{
            tickets: 1,
            helper: <div className={'py-4'}>{t('game_pack_only_1_ticket_allowed_helper')}</div>
    }];
    }
    return customPacks.split(/\s*,\s*/g).reduce((acc, p, _) => {
        let popular = false;
        let n = p;
        if ('*' === p.slice(-1)) {
            n = p.slice(0, p.length - 1);
            popular = true;
        }
        acc.push({
            tickets: parseInt(n),
            ...(popular ? {overline: t('game_pack_ticket_option')} : {}),
        });
        return acc;
    }, [] as {code?: string, tickets: number, name?: string, overline?: string}[]);
}

export default buildPacksFromCustomPacks;
