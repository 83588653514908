import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function IdBuiltField(props: IdBuiltFieldProps) {
    return <TextBuildField name={'id'} {...props} />
}

export interface IdBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default IdBuiltField;