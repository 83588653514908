import React, {useCallback} from "react";
import {cleanValue} from "../../utils/cleaner";
import clsx from "clsx";

export function TextBuildField({name, label = false, placeholder, type = 'text', onChange, values, t, required, autofocus, min, max}: TextBuiltFieldProps) {
    const handleOnChange = useCallback((event: any) => {
        onChange && onChange({...values, [name]: cleanValue(name, event.target.value)});
    }, [onChange, values]);

    return (
        <fieldset className={'w-full space-y-2'}>
            {!!label && <label htmlFor={name} className={'text-sm pl-1'}>{true !== label ? label : t(`field_${(name || '').toLowerCase()}_label`)}</label>}
            <input onChange={handleOnChange} type={type}
                   value={values?.[name]}
                   placeholder={false === placeholder ? undefined : (placeholder || t(`field_${(name || '').toLowerCase()}_placeholder`))}
                   className={clsx("font-go-text text-go-text border p-3 rounded-md w-full", required ? 'border-gray-500' : 'border-gray-300')}
                   autoFocus={autofocus || false} min={min} max={max}
            />
        </fieldset>
    );
}

export interface BaseTextBuiltFieldProps {
    type?: string;
    label?: any;
    placeholder?: any;
    onChange: (data: any) => void;
    values: any;
    t: Function;
    required?: boolean;
    autofocus?: boolean;
    min?: number;
    max?: number;
}
export interface TextBuiltFieldProps extends BaseTextBuiltFieldProps {
    name: string;
}

export default TextBuildField;