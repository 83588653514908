import React from 'react';
import FramedBlock from "../../mods/sale/molecules/FramedBlock";

export function ImageAdFormat({ url, image, imageDesktop, imageTablet, imageMobile, title, subtitle }: ImageAdFormatProps) {
    if (!image && !imageDesktop && !imageTablet && !imageMobile) return null;

    return (
        <FramedBlock className={'hover:bg-go-primary hover:text-white cursor-pointer'} nopadding selectable>
            <a href={url} target={"_blank"} className={'w-full block'}>
                <picture>
                    {!!imageDesktop && <source media={`(min-width: ${!!imageTablet ? '1024' : (!!imageMobile ? '640' : '0')}px`} srcSet={imageDesktop} />}
                    {!!imageTablet && <source media={`(min-width: ${!!imageMobile ? '640' : '0'}px`} srcSet={imageTablet} />}
                    {!!imageMobile && <source media={'(min-width: 0px)'} srcSet={imageMobile} />}
                    {<img className={'w-full'} alt={title || subtitle || ''} src={image || imageMobile || imageTablet || imageDesktop} />}
                </picture>
            </a>
        </FramedBlock>
    );
}

export interface ImageAdFormatProps {
    url?: string;
    title?: string;
    subtitle?: string;
    image?: string;
    imageMobile?: string;
    imageDesktop?: string;
    imageTablet?: string;
}

export default ImageAdFormat;
