import {useMemo} from "react";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";

export function useGameBuyBeneficiaries(game: any, { t, workflows }: { t: Function; workflows: any }) {
    const urlBuilder = useBuildUrlFor('game', game, false);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'beneficiary', {game});
    const beneficiaries = game?.beneficiaries?.items?.map((beneficiary: any) => {
        return {code: beneficiary.code, label: beneficiary.name}
    }) || [];
    beneficiaries.push({code: null, label: t('game_buy_beneficiary_other_choice')});
    return useMemo(() => beneficiaries.map(({code, label}: any, index: number) => {
        if (!!code) {
            query.set('b', code);
        } else {
            query.delete('b');
        }
        return {
            id: beneficiaries.code || index,
            target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
            label: label,
        };
    }), [beneficiaries, query, game, nextStep, urlBuilder]);
}

export default useGameBuyBeneficiaries;
