import {useMemo} from "react";
import buildPacksFromCustomPacks from "../utils/buildPacksFromCustomPacks";
import { getFinalPacks } from "@gotombola/custom-packs";

function useGameDefaultPacks(game: any, { t, defaultCustomPacks }: { t: Function; defaultCustomPacks?: string }) {
    return useMemo(
        () => buildPacksFromCustomPacks(
            getFinalPacks(game, defaultCustomPacks),
            {t}
        ),
        [t, game, defaultCustomPacks]
    );
}

export default useGameDefaultPacks;

