import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function CountryBuiltField(props: CountryBuiltFieldProps) {
    return <TextBuildField name={'country'} {...props} />
}

export interface CountryBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default CountryBuiltField;