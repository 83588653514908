import {useMemo} from "react";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";

export function useGameProjects(game: any, { t, workflows }: { t: Function; workflows: any}) {
    const urlBuilder = useBuildUrlFor('game', game, false);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'project', {game});
    const projects = useMemo(() => {
        const projects: {code: any, label: any}[] = game?.projects?.items?.map((project: any) => ({code: project.code, label: project.name})) || [];
        projects.push({code: null, label: t('game_buy_project_other_choice')});
        return projects;
    }, [game.projects.items, t]);
    return useMemo(() => projects.map(({code, label}: any, index: number) => {
        if (!!code) {
            query.set('pr', code);
        } else {
            query.delete('pr');
        }
        return {
            id: code || index,
            target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
            label: label,
        };
    }), [projects, query, game, urlBuilder]);
}

export default useGameProjects;
