const sortModeFns = {
    default: (a: any, b: any) => {
        if(a.rank) {
            if(!a.id) return 1;
            return (a.rank || '').toString().localeCompare((b.rank || '').toString());
        } else {
            if (!a.id) return 1;
            return a.label.localeCompare(b.label);
        }
    },
    rank: (a: any, b: any) => {
        if (a.rank !== undefined) {
            if (b.rank !== undefined) {
                return a.rank < b.rank ? -1 : (a.rank > b.rank ? 1 : 0);
            }
            return -1;
        }
        if (b.rank !== undefined) return 1;
        return 0;
    },
    alpha: (a: any, b: any) => {
        return (a?.label || a?.name || '').toString().localeCompare((b?.label || b?.name || '').toString());
    },
}

export function applySort(items: any[], mode: string|undefined, defaultMode?: string) {
    const sortModeFn = sortModeFns[mode || defaultMode || ''] || sortModeFns[defaultMode || ''];
    sortModeFn && (items || []).sort(sortModeFn);
}