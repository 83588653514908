import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function ZipCodeBuiltField(props: ZipCodeBuiltFieldProps) {
    return <TextBuildField name={'zipCode'} {...props} />
}

export interface ZipCodeBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default ZipCodeBuiltField;