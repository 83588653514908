import React, {useMemo} from "react";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";

export function useGameBuyBookPayments(book: any, game: any, { t, workflows }: { t: Function; workflows: any }) {
    const urlBuilder = useBuildUrlFor('game_buy_book', book, false);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'bookpayment', 'payment', {game, book});
    const paymentMeans = ['twint', 'stripe', 'paypal', 'helloasso', 'lydia', 'paypal_account'].map((feature: string) => {
        if (!game.features || !game.features.includes(feature)) return null;
        switch (feature) {
            case "stripe":
                return {id: "stripe-card", label: t('game_buy_book_payment_page_stripe_option')}
            case "helloasso":
                return {id: "helloasso", label: t('game_buy_book_payment_page_helloasso_option')}
            case "lydia":
                return {id: "lydia", label: t('game_buy_book_payment_page_lydia_option')}
            case "paypal":
                return {id: "paypal", label: t('game_buy_book_payment_page_paypal_option')}
            case "paypal_account":
                return {id: "paypal", label: t('game_buy_book_payment_page_paypal_account_option')}
            case "promise":
                return {id: "promise", label: t('game_buy_book_payment_page_pay_later_option'), helper: t('game_buy_book_payment_page_pay_later_helper'), ifAlone: t('game_buy_book_payment_page_pay_later_ifalone')}
            case "preorder":
                return {id: "preorder", label: t('game_buy_book_payment_page_pre_order_option'), helper: t('game_buy_book_payment_page_pre_order_helper'), ifAlone: t('game_buy_book_payment_page_pre_order_ifalone')}
            case "twint":
                return {id: "twint", label: t('game_buy_book_payment_page_twint_option'), helper: t('game_buy_book_payment_page_twint_helper'), ifAlone: t('game_buy_book_payment_page_twint_ifalone')}
            default:
                return null
        }
    }).filter((item: any) => !!item);
    const isPromiseOnly = (paymentMeans?.length === 1) && (((paymentMeans || [{}])[0] || {}).id === 'promise');
    const items = useMemo(() => (paymentMeans || []).map((paymentMean: any, index: number) => {
        query.set('p', paymentMean.id);
        const alone = paymentMeans?.length === 1;
        return {
            id: paymentMean.code || index,
            target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
            value: paymentMean.value,
            helper: <>
                {(!alone || (alone && !paymentMean.ifAlone)) && !!paymentMean.helper && (
                    <div className={'font-go-text text-go-text pt-4'}>{paymentMean.helper}</div>
                )}
                {alone && !!paymentMean.ifAlone && (
                    <div className={'font-go-text text-go-text pb-4'}>{paymentMean.ifAlone}</div>
                )}
            </>,
            label: paymentMean.label,
        };
    }), [paymentMeans, query, game, urlBuilder]);

    return useMemo(() => [items, { isPromiseOnly }], [items, isPromiseOnly]) as [any[], { isPromiseOnly: boolean }];
}

export default useGameBuyBookPayments;
