import {useMemo} from "react";
import useGameDefaultBonuses from "./useGameDefaultBonuses";
import formatRawAmount from "@gotombola/amount/lib/utils/formatRawAmount";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";

export function useGameBuyBonuses(game: any, { t, workflows }: { t: Function; workflows: any }) {
    const urlBuilder = useBuildUrlFor('game', game, false);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'bonus', {game});
    const quantity = Number(query.get('q') || "0");
    const defaultBonuses = useGameDefaultBonuses(game, { t });
    return useMemo(() => (defaultBonuses || []).map((donation: any, index) => {
        query.set('q', quantity + donation.tickets);
        const extraCost = (donation.tickets ? (donation.tickets * game.ticketPriceAmount) : 0) + (donation.donation * 100);
        if (donation.donation > 0) {
            query.set('d', String(donation.donation * 100));
        } else {
            query.delete('d');
        }
        return {
            id: `donation-${index}`,
            target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
            label: donation.label,
            overline: donation.overline,
            value: extraCost > 0 ? `+ ${formatRawAmount(extraCost, game.ticketPriceCurrency)}` : '...',
        };
    }), [query, quantity, game.ticketPriceAmount, game.ticketPriceCurrency, urlBuilder]);
}

export default useGameBuyBonuses;
