import React from 'react'
import String from "@gotombola/react-ds/lib/atoms/String";

export function SecondaryMessage({message}: SecondaryMessageProps) {
    if (!message) return null;
    return <String className={'mt-6 font-go-text text-go-text w-full font-light text-center sm:text-justify'}>{message}</String>
}

export interface SecondaryMessageProps {
    message?: string;
}

export default SecondaryMessage;