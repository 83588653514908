import React, {useCallback, useMemo} from "react";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";

export function useGameBuyPayments(game: any, { t, workflows}: { t: Function; workflows: any }) {
    const urlBuilder = useBuildUrlFor('game', game, false);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'payment', {game});
    let paymentMeans = ['twint', 'stripe', 'paypal', 'helloasso', 'lydia', 'paypal_account', 'promise', 'preorder'].map((feature: string) => {
        if (!game.features || !game.features.includes(feature) || (!!game.features.includes(`hide_${feature}`) && query.get('p') !== feature)) return null;
        switch (feature) {
            case "stripe":
                return {id: "stripe-card", label: t('game_buy_payment_page_stripe_option')}
            case "helloasso":
                return {id: "helloasso", label: t('game_buy_payment_page_helloasso_option')}
            case "lydia":
                return {id: "lydia", label: t('game_buy_payment_page_lydia_option')}
            case "paypal":
                return {id: "paypal", label: t('game_buy_payment_page_paypal_option')}
            case "paypal_account":
                return {id: "paypal", label: t('game_buy_payment_page_paypal_account_option')}
            case "promise":
                return {id: "promise", label: t('game_buy_payment_page_pay_later_option'), helper: t('game_buy_payment_page_pay_later_helper'), ifAlone: t('game_buy_payment_page_pay_later_ifalone')}
            case "preorder":
                return {id: "preorder", label: t('game_buy_payment_page_pre_order_option'), helper: t('game_buy_payment_page_pre_order_helper'), ifAlone: t('game_buy_payment_page_pre_order_ifalone')}
            case "twint":
                return {id: "twint", label: t('game_buy_payment_page_twint_option')}
            default:
                return null
        }
    }).filter((item: any) => !!item);

    let blockMode = false;

    // if only promise BUT free mode enabled, do not display promise and go to next screen
    if (paymentMeans.length === 1 && paymentMeans?.[0]?.id === 'promise' && game?.features?.includes('free')) {
        paymentMeans = [];
    }
    // if only preorder BUT free mode enabled, do not display preorder and go to next screen
    if (paymentMeans.length === 1 && paymentMeans?.[0]?.id === 'preorder' && game?.features?.includes('free')) {
        paymentMeans = [];
    }

    const isPromiseOnly = (paymentMeans.length === 1) && (((paymentMeans || [{}])[0] || {}).id === 'promise');
    const isPreorderOnly = (paymentMeans.length === 1) && (((paymentMeans || [{}])[0] || {}).id === 'preorder');
    const items = useMemo(() => (paymentMeans || []).map((paymentMean: any, index: number) => {
        query.set('p', paymentMean.id);
        const alone = paymentMeans.length === 1;
        return {
            id: paymentMean.code || index,
            target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
            value: paymentMean.value,
            helper: <>
                {(!alone || (alone && !paymentMean.ifAlone)) && !!paymentMean.helper && (
                <div className={'font-go-text text-go-text pt-4'}>{paymentMean.helper}</div>
            )}
        {alone && !!paymentMean.ifAlone && (
            <div className={'font-go-text text-go-text pb-4'}>{paymentMean.ifAlone}</div>
        )}
        </>,
        label: paymentMean.label,
    };
    }), [paymentMeans, query, game, urlBuilder]);

    const buildNextStepUrl = useCallback(() => {
        return buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder)
    }, [game, nextStep, query, urlBuilder]);

    // if no payment means but ticket price is not free, display an error message
    if (items.length === 0 && game.ticketPriceAmount > 0) {
        blockMode = true;
    }

    return useMemo(() => [items, { buildNextStepUrl, blockMode, isPromiseOnly, isPreorderOnly }], [items, buildNextStepUrl, blockMode, isPromiseOnly, isPreorderOnly]) as [any[], { buildNextStepUrl: Function; blockMode: boolean; isPromiseOnly: boolean; isPreorderOnly: boolean }];
}

export default useGameBuyPayments;
