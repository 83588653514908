import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function AgeBuiltField(props: AgeBuiltFieldProps) {
    return <TextBuildField name={'age'} {...props} type={'number'} />
}

export interface AgeBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default AgeBuiltField;