import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function OrganizationBuiltField(props: OrganizationBuiltFieldProps) {
    return <TextBuildField name={'organization'} {...props} />
}

export interface OrganizationBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default OrganizationBuiltField;