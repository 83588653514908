import React from "react";
import TextBuildField, {BaseTextBuiltFieldProps} from "./TextBuiltField";

export function PhoneBuiltField(props: PhoneBuiltFieldProps) {
    return <TextBuildField name={'phone'} {...props} />
}

export interface PhoneBuiltFieldProps extends BaseTextBuiltFieldProps {
}

export default PhoneBuiltField;