import {useMemo} from "react";
import useGameDefaultPacks from "./useGameDefaultPacks";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import formatRawAmount from "@gotombola/amount/lib/utils/formatRawAmount";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";

export function useGameBuyQuantities(game: any, { t, locale, workflows }: { t: Function; locale: string; workflows: any }) {
    const urlBuilder = useBuildUrlFor('game', game, false);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'quantity', {game});
    const defaultPacks = useGameDefaultPacks(game, { t });
    const packs = ((game?.packs?.items?.length || 0) > 0) ? game.packs.items : defaultPacks;
    return useMemo(() => {
        const p = packs.map((pack: any, index: number) => {
            !pack.code && query.set('q', pack.tickets);
            !!pack.code && query.set('pk', pack.code);
            const price = (pack.price || pack.tickets * game.ticketPriceAmount);
            return {
                id: pack.code || index,
                target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
                label: (pack.name ? ('-' !== pack.name ? pack.name : undefined) : undefined) ||
                    t(game.itemTypeInfos ? 'game_pack_item_quantity' : 'game_pack_item_default_quantity',
                        {
                            count: pack.tickets,
                            ...(game.itemTypeInfos ? {item: game.itemTypeInfos && game.itemTypeInfos.locales && game.itemTypeInfos.locales[locale] ?
                                    pack.tickets > 1 ?
                                        game.itemTypeInfos.locales[locale].plural :
                                        game.itemTypeInfos.locales[locale].singular
                                    :
                                    pack.tickets > 1 ?
                                        game.itemTypeInfos.plural :
                                        game.itemTypeInfos.singular
                            } : {})}),
                value: price > 0 ? `${formatRawAmount(price, game.ticketPriceCurrency)}` : t('price_is_free'),
                ...(pack?.helper ? {helper: pack.helper} : {}),
                overline: (pack.overline === '*') ? t('game_pack_ticket_option') : (pack.overline || '').toUpperCase(),
            };
        });
        if (game?.features?.includes('donation_only_allowed')) {
            query.set('q', '0');
            query.delete('pk');
            p.push({
                id: p.length,
                target: buildGameNextStepUrl(game, {url: 'game_buy_donation'}, query, undefined, undefined, urlBuilder),
                label: t('game_pack_item_only_donation'),
                value: '...',
            });
        }
        if (game?.features?.includes('custom_ticket_quantity')) {
            query.set('q', '0');
            query.delete('pk');
            p.push({
                id: p.length,
                target: buildGameNextStepUrl(game, {url: 'game_buy_custom_quantity'}, query, undefined, undefined, urlBuilder),
                label: t('game_pack_item_custom_ticket_quantity'),
                value: '...',
            });
        }
        return p;
    }, [packs, nextStep, urlBuilder, query, t]);
}

export default useGameBuyQuantities;